<template>
  <DefaultLayout :with-border="true">
    <StatusResult />
    <div
      class="flex w-full items-center justify-center grow self-center xl:grid xl:h-full xl:grid-cols-2 xl:justify-items-center xl:gap-2 pt-2"
    >
      <div
        :style="`background-image: url(${LOGIN_IMAGE})`"
        alt="test"
        class="hidden rounded border border-neutral-7 contrast:border-base-1 shadow-xl xl:block w-full h-full bg-center bg-cover bg-neutral-9"
      />
      <div
        class="sm:min-w-[28rem] px-1 sm:px-6 w-full sm:w-auto sm:max-w-[28rem]"
      >
        <NuxtPage />
      </div>
    </div>
  </DefaultLayout>
</template>
<script setup lang="ts">
const config = useRuntimeConfig();
const LOGIN_IMAGE = `${config.app.baseURL}/${config.public.loginImage}`;
</script>
